<template>
  <homeview />
</template>

<script>
  import homeview from '../components/Home'

  export default {
    name: 'Home',

    components: {
      homeview,
    },
  }
</script>
