<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo.png')"
          class="my-3 mx-auto"
          contain
          height="200"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">Plan-It Dating</h1>

        <p class="subheading font-weight-regular">
          Plan. Apply. Date. If you plan-it, the dates will come
        </p>
      </v-col>

      <v-col class="mb-5" cols="12">
        <h2 class="headline font-weight-bold mb-3">
          <a href="about">How this works.</a>
        </h2>

        <v-row justify="center">
          <v-col cols="10" md="3">
            <v-btn
              class="white--text"
              color="#0083b0"
              href="https://forms.gle/MVnehjjXLbJswBEA8"
            >
              Plan a date</v-btn
            >
          </v-col>
          <v-col cols="10" md="3">
            <v-btn
              class="white--text"
              color="#0083b0"
              href="https://www.instagram.com/planitdating/"
            >
              View the dates!</v-btn
            >
          </v-col>
          <v-col cols="10" md="3">
            <v-btn
              class="white--text"
              color="#0083b0"
              href="https://forms.gle/FGy884CMospGmRC68"
            >
              Apply for a date!</v-btn
            >
          </v-col>
        </v-row>
      </v-col>

      <v-col class="mb-5" cols="12">
        <h2 class="headline font-weight-bold mb-3">Coming Soon</h2>

        <v-row justify="center">
          <v-col cols="10" md="6">
            <v-img
              :src="require('../assets/google-play-badge.svg')"
              class="my-3 mx-auto"
              width="250px"
            />
          </v-col>
          <v-col cols="12" md="6" class="mx-auto">
            <v-img
              :src="require('../assets/download-on-the-app-store.svg')"
              class="my-3 mx-auto"
              width="230px"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col class="mb-5" cols="12">
        <h2 class="headline font-weight-bold mb-3">
          @planitdating
          <v-btn
            large
            href="https://www.instagram.com/planitdating/"
            target="_blank"
            icon
            color="#F56040"
            ><v-icon>mdi-instagram </v-icon></v-btn
          >
          Follow us to stay up-to-date on...dates.
        </h2>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HelloWorld",

  data: () => ({}),
};
</script>
