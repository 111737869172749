<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
    <v-btn icon to="/">
        <v-img
          :src="require('./assets/logo.png')"
          class="my-3"
          contain
          height="20"
        />
    </v-btn>
    Plan-it Dating

    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
